const enMessages = {
  'menu.dashboards': 'es|Dashboards',
  'menu.default': 'es|Default',
  'menu.visual': 'es|Visual',
  'menu.analytic': 'es|Analytic',
  'menu.apps': 'es|Apps',
  'menu.calendar': 'es|Calendar',
  'menu.chat': 'es|Chat',
  'menu.contacts': 'es|Contacts',
  'menu.mailbox': 'es|Mailbox',
  'menu.tasks': 'es|Tasks',
  'menu.pages': 'es|Pages',
  'menu.authentication': 'es|Authentication',
  'menu.login': 'es|Login',
  'menu.register': 'es|Register',
  'menu.forgot-password': 'es|Forgot Password',
  'menu.reset-password': 'es|Reset Password',
  'menu.blog': 'es|Blog',
  'menu.home': 'es|Home',
  'menu.grid': 'es|Grid',
  'menu.list': 'es|List',
  'menu.detail': 'es|Detail',
  'menu.miscellaneous': 'es|Miscellaneous',
  'menu.faq': 'es|Faq',
  'menu.knowledge-base': 'es|Knowledge Base',
  'menu.error': 'es|Error',
  'menu.coming-soon': 'es|Coming Soon',
  'menu.pricing': 'es|Pricing',
  'menu.search': 'es|Search',
  'menu.mailing': 'es|Mailing',
  'menu.empty': 'es|Empty',
  'menu.portfolio': 'es|Portfolio',
  'menu.profile': 'es|Profile',
  'menu.standard': 'es|Standard',
  'menu.settings': 'es|Settings',
  'menu.blocks': 'es|Blocks',
  'menu.images': 'es|Images',
  'menu.tabular-data': 'es|Tabular Data',
  'menu.thumbnails': 'es|Thumbnails',
  'menu.cta': 'es|Cta',
  'menu.gallery': 'es|Gallery',
  'menu.stats': 'es|Stats',
  'menu.steps': 'es|Steps',
  'menu.details': 'es|Details',
  'menu.interface': 'es|Interface',
  'menu.components': 'es|Components',
  'menu.accordion': 'es|Accordion',
  'menu.alerts': 'es|Alerts',
  'menu.badge': 'es|Badge',
  'menu.breadcrumb': 'es|Breadcrumb',
  'menu.buttons': 'es|Buttons',
  'menu.button-group': 'es|Button Group',
  'menu.card': 'es|Card',
  'menu.close-button': 'es|Close Button',
  'menu.collapse': 'es|Collapse',
  'menu.dropdowns': 'es|Dropdowns',
  'menu.list-group': 'es|List Group',
  'menu.modal': 'es|Modal',
  'menu.navs': 'es|Navs',
  'menu.offcanvas': 'es|Offcanvas',
  'menu.pagination': 'es|Pagination',
  'menu.popovers': 'es|Popovers',
  'menu.progress': 'es|Progress',
  'menu.spinners': 'es|Spinners',
  'menu.toasts': 'es|Toasts',
  'menu.tooltips': 'es|Tooltips',
  'menu.forms': 'es|Forms',
  'menu.layouts': 'es|Layouts',
  'menu.validation': 'es|Validation',
  'menu.wizard': 'es|Wizard',
  'menu.input-group': 'es|Input Group',
  'menu.input-mask': 'es|Input Mask',
  'menu.generic-forms': 'es|Generic Forms',
  'menu.controls': 'es|Controls',
  'menu.autocomplete': 'es|Autocomplete',
  'menu.checkbox-radio': 'es|Checkbox-Radio',
  'menu.date-picker': 'es|Date Picker',
  'menu.dropzone': 'es|Dropzone',
  'menu.editor': 'es|Editor',
  'menu.input-spinner': 'es|Input Spinner',
  'menu.rating': 'es|Rating',
  'menu.select': 'es|Select',
  'menu.slider': 'es|Slider',
  'menu.tags': 'es|Tags',
  'menu.time-picker': 'es|Time Picker',
  'menu.plugins': 'es|Plugins',
  'menu.carousel': 'es|Carousel',
  'menu.charts': 'es|Charts',
  'menu.clamp': 'es|Clamp',
  'menu.context-menu': 'es|Context Menu',
  'menu.datatables': 'es|Datatables',
  'menu.editable-rows': 'es|Editable Rows',
  'menu.editable-boxed': 'es|Editable Boxed',
  'menu.server-side': 'es|Server Side',
  'menu.boxed-variations': 'es|Boxed Variations',
  'menu.lightbox': 'es|Lightbox',
  'menu.maps': 'es|Maps',
  'menu.notification': 'es|Notification',
  'menu.players': 'es|Players',
  'menu.scrollbar': 'es|Scrollbar',
  'menu.shortcuts': 'es|Shortcuts',
  'menu.sortable': 'es|Sortable',
  'menu.content': 'es|Content',
  'menu.icons': 'es|Icons',
  'menu.cs-line-icons': 'es|CS Line Icons',
  'menu.cs-interface-icons': 'es|CS Interface Icons',
  'menu.bootstrap-icons': 'es|Bootstrap Icons',
  'menu.tables': 'es|Tables',
  'menu.typography': 'es|Typography',
  'menu.menu': 'es|Menu',
  'menu.horizontal': 'es|Horizontal',
  'menu.vertical': 'es|Vertical',
  'menu.vertical-hidden': 'es|Vertical Hidden',
  'menu.vertical-no-hidden': 'es|Vertical No Hidden',
  'menu.mobile-only': 'es|Mobile Only',
  'menu.sidebar': 'es|Sidebar',
  'menu.Docs': 'es|Docs',
  'menu.connections': 'es|Connections',
  'menu.bookmarks': 'es|Bookmarks',
  'menu.requests': 'es|Requests',
  'menu.account': 'es|Account',
  'menu.password': 'es|Password',
  'menu.devices': 'es|Devices',
  'menu.notifications': 'es|Notifications',
  'menu.email': 'es|Email',
  'menu.sms': 'es|SMS',
  'menu.downloads': 'es|Downloads',
  'menu.documents': 'es|Documents',
  'menu.videos': 'es|Videos',
  'menu.dashboard': 'es|Dashboard',
  'menu.products': 'es|Products',
  'menu.orders': 'es|Orders',
  'menu.customers': 'es|Customers',
  'menu.storefront': 'es|Storefront',
  'menu.filters': 'es|Filters',
  'menu.categories': 'es|Categories',
  'menu.cart': 'es|Cart',
  'menu.checkout': 'es|Checkout',
  'menu.invoice': 'es|Invoice',
  'menu.shipping': 'es|Shipping',
  'menu.discount': 'es|Discount',
  'menu.general': 'es|General',

  'menu.upgrade': 'es|Upgrade',
  'menu.community': 'es|Community',
  'menu.community-list': 'es|Community List',
  'menu.getting-started': 'es|Getting Started',
  'menu.analysis': 'es|Analysis',
  'menu.services': 'es|Services',
  'menu.database': 'es|Database',
  'menu.database-add': 'es|New Database',
  'menu.database-detail': 'es|Database Detail',
  'menu.storage': 'es|Storage',
  'menu.hosting': 'es|Hosting',
  'menu.users': 'es|Users',
  'menu.billing': 'es|Billing',
  'menu.security': 'es|Security',
  'menu.support': 'es|Support',
  'menu.docs': 'es|Docs',
  'menu.docsdetail': 'es|Docs Detail',
  'menu.tickets': 'es|Tickets',
  'menu.tickets-detail': 'es|Teicket Detail',

  'menu.elearning': 'es|Elearning',
  'menu.school': 'es|School',
  'menu.courses': 'es|Courses',
  'menu.explore': 'es|Explore',
  'menu.quiz': 'es|Quiz',
  'menu.result': 'es|Result',
  'menu.paths': 'es|Paths',
  'menu.instructor': 'es|Instructor',
  'menu.misc': 'es|Miscellaneous',
  'menu.player': 'es|Player',
  'menu.material': 'es|Material',
  'menu.syllabus': 'es|Syllabus',
  'menu.marketing': 'es|Marketing',
  'menu.penalties': 'es|Penalties',
};
export default enMessages;
