import { gql } from '@apollo/client';

export const GET_PRODUCT_DETAIL = () => gql`
  query GetProductDetail($id: String) {
    getProductDetail(id: $id) {
      attribute_set_id
      attribute_set_name
      media_gallery {
        value
        position
        disabled
        sliderPosition
        video_url
      }
      fields {
        key
        numberValue
        boolValue
        stringValue
        dateValue
        lang {
          en
          ar
        }
      }
      fieldset {
        _id
        groups {
          name
          fields {
            _id
            frontend_label
            frontend_input
            is_required
            is_visible
            options {
              option_id
              values {
                en {
                  label
                }
              }
            }
          }
        }
      }
      inventory {
        _id
        name
        country
        warehouseCode
        warehouseName
        priority
        qty
        salableQty
        inStock
        latitude
        longitude
        isPickupLocationActive
      }
      draft {
        attribute_set_id
        media_gallery {
          value
          position
          disabled
          sliderPosition
          video_url
        }
        fields {
          key
          numberValue
          boolValue
          stringValue
          dateValue
          lang {
            en
            ar
          }
        }
        categories
      }
      reason
      rejectChecks
      rejectedBy {
        email
        userId
      }
      categories
      type_id
      groupedProducts {
        sku
        qty
      }
      created_at
      createdBy {
        email
      }
      shops
      prices {
        country
        offerId
        status
        customerGroup
        currency
        specialPrice
        lowestPrice
        priority
        offerPeriod {
          startDate
          endDate
        }
      }
      partnerSku {
        sku
        barcode
        seller
        costs {
          country
          cost
        }
      }
      partnerSkus {
        seller
        sku
        barcode
        lsItemCode
      }
      ratingAverage
      approvalStatus
    }
  }
`;

export const ADD_PRODUCT_DETAIL = () => gql`
  mutation AddProductDetail($id: String, $fields: [InputFieldItem], $categories: [Int], $attributeSetId: Int, $comment: String, $checks: [String]) {
    addProductDetail(id: $id, fields: $fields, categories: $categories, attributeSetId: $attributeSetId, comment: $comment, checks: $checks) {
      attribute_set_id
    }
  }
`;

export const UPDATE_MEDIA_PRODUCT_DETAIL = () => gql`
  mutation UpdateMediaProductDetail($id: String, $preFile: [InputMediaGallery], $postFile: [UploadItem]) {
    updateMediaProductDetail(id: $id, preFile: $preFile, postFile: $postFile) {
      success
      errors
    }
  }
`;

export const ADD_PARTNER_SKU = () => gql`
  mutation AddPartnerSku($id: String, $partnerSku: String, $partnerBarcode: String, $costs: [InputCostItem]) {
    addPartnerSku(id: $id, partnerSku: $partnerSku, partnerBarcode: $partnerBarcode, costs: $costs) {
      attribute_set_id
    }
  }
`;

export const ADD_GROUP_PRODUCT = () => gql`
  mutation AddGroupProduct($id: String, $groupedProducts: [InputGroupedProductItem]) {
    addGroupProduct(id: $id, groupedProducts: $groupedProducts) {
      attribute_set_id
    }
  }
`;

export const ADD_RELATED_PRODUCT = () => gql`
  mutation AddRelatedProduct($ids: [String], $baseId: String, $relatedBy: String, $delete: Boolean) {
    addRelatedProduct(ids: $ids, baseId: $baseId, relatedBy: $relatedBy, delete: $delete) {
      attribute_set_id
    }
  }
`;

export const ADD_PRODUCT_PRICES = () => gql`
  mutation AddProductPrices($id: String, $prices: [InputPriceItem]) {
    addProductPrices(id: $id, prices: $prices) {
      attribute_set_id
    }
  }
`;

export const UPDATE_PRODUCT_STATUS = () => gql`
  mutation UpdateProductStatus(
    $id: String
    $type: String
    $contentStatus: Boolean
    $translationStatus: Boolean
    $enableStatus: Boolean
    $liveStatus: Boolean
    $countries: [Int]
  ) {
    updateProductStatus(
      id: $id
      type: $type
      contentStatus: $contentStatus
      translationStatus: $translationStatus
      enableStatus: $enableStatus
      liveStatus: $liveStatus
      countries: $countries
    ) {
      _id
    }
  }
`;
